import React, { useContext } from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

import { CartContext } from "./useContextFile"
import { Link } from 'react-router-dom'
import QuantityBtn from './QuantityBtn'

import { FaCcPaypal, FaCcVisa, FaAlipay, FaCcApplePay } from "react-icons/fa"


export default function CheckOut() {
  let { cartItems } = useContext(CartContext)
  let cartEmtry = (cartItems.length === 0 ? false : true)
  let priceTotal = cartItems.reduce((total, product) => {
    return total += product.price * product.quantity
  }, 0)
  let deliveryFee = (priceTotal >= 10000 ? "Free" : priceTotal * 0.1)
  let priceWithDeliveryFee = (priceTotal >= 10000 ? priceTotal : Math.round(priceTotal * 1.1))

  return (
    <>
      <Navbar />

      {cartEmtry &&
        <div className='flex'>
          <div className='w-3/5 md:w-9/12 box-border px-2.5'>
            {cartItems.map((product) => {
              return (
                <div className='container w-full grid mx-auto grid-cols-12 gap-5'>
                  <div className='col-span-12 md:col-span-6'>
                    <div className='flex flex-col items-center justify-center w-full'>
                      <div className='bg-custom-black p-2 relative before:content-[""] before:w-5 before:absolute before:h-5 before:top-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green after:content-[""] after:w-5 after:absolute after:h-5 after:bottom-0 after:left-0 after:bg-gradient-to-r after:from-green-200 after:var-green-500 after:to-custom-green'>
                        <img src={process.env.PUBLIC_URL + '/img/' + product.image} alt="" className='w-full' />
                      </div>
                    </div>
                  </div>
                  <div className='col-span-12 md:col-span-6'>
                    <div className='flex items-center flex-col justify-center w-full h-full'>
                      <div className='w-full'>
                        <ProductInfoText TextHeading={'Name'} Text={product.name} />
                        <ProductInfoText TextHeading={'Price'} Text={product.price} />
                        <ProductInfoText TextHeading={'Quantity'} Text={product.quantity} />
                        <ProductInfoText TextHeading={'Sub Total'} Text={product.quantity * product.price} />
                      </div>
                      <div className='w-full'>
                        <QuantityBtn productInfo={product} />
                      </div>
                    </div>
                  </div>
                  <div className='cutline w-full h-0.5 bg-custom-black my-5 md:my-8 col-span-12 opacity-[0.5]'></div>
                </div>
              )
            })}
          </div>

          <div className='w-2/5 md:w-3/12 box-border px-2.5'>
            <div className='w-full bg-custom-black sticky top-[8vh] px-2.5 py-2 before:content-[""] before:w-full before:absolute before:h-0.5 before:top-0 before:left-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:animate-[lineLoading_2.5s_infinite] before:origin-left'>

              <div className='checkoutText'>
                <h1 className='text-custom-white text-lg my-3 md:my-5 mx-1.5 md:mx-2 py-2 md:py-4 hover:text-custom-green relative before:content-[""] before:w-full before:h-0.5 before:bg-custom-green before:absolute before:bottom-0'>Summary</h1>
                <p className='text-custom-white text-xs md:text-base my-3 md:my-5 mx-1.5 md:mx-2 py-2 md:py-4 hover:text-custom-green relative before:content-[""] before:w-full before:h-0.5 before:bg-custom-green before:absolute before:bottom-0'>Total: $ {priceTotal}</p>
                <p className='text-custom-white text-xs md:text-base my-3 md:my-5 mx-1.5 md:mx-2 py-2 md:py-4 hover:text-custom-green relative before:content-[""] before:w-full before:h-0.5 before:bg-custom-green before:absolute before:bottom-0'>Delivery: {deliveryFee}</p>
                <p className='text-custom-white text-xs md:text-base my-3 md:my-5 mx-1.5 md:mx-2 py-2 md:py-4 hover:text-custom-green relative before:content-[""] before:w-full before:h-0.5 before:bg-custom-green before:absolute before:bottom-0'>Total: $ {priceWithDeliveryFee}</p>
              </div>

              <div className='flex mx-auto items-center px-2'>
                <FaCcPaypal className='text-custom-white text-3xl mx-1.5 hover:text-custom-blue' />
                <FaCcVisa className='text-custom-white text-3xl mx-1.5 hover:text-custom-blue' />
                <FaAlipay className='text-custom-white text-3xl mx-1.5 hover:text-custom-blue' />
                <FaCcApplePay className='text-custom-white text-3xl mx-1.5 hover:text-custom-blue' />
              </div>

              <button className='text-custom-white w-full p-1.5 bg-gradient-to-r from-green-400 to-custom-green my-2 md:my-4'>CHECKOUT</button>
            </div>
          </div>
        </div>
      }


      {
        !cartEmtry &&
        <div className='w-full flex items-center justify-center flex-col h-[100vh]'>
          <div className='flex items-center justify-center flex-col'>
            <div className='my-customer-cartHandle'></div>
            <div className='my-customer-cartBody'></div>
          </div>
          <p className='text-base m-2 text-custom-black'>Your cart is empty</p>
          <Link to="/">
            <button className='m-2 bg-transparent text-custom-black px-3 py-1.5 rounded hover:bg-custom-black hover:text-custom-white border-solid border-2'>Back to Shop</button>
          </Link>
        </div>
      }

      <Footer />
    </>
  )
}

function ProductInfoText({ TextHeading, Text }) {
  return (
    <>
      <div className='w-full flex justify-center my-1'>
        <div className='flex-initial text-custom-white text-xs md:text-base box-border p-2 bg-custom-black w-1/4 flex items-center justify-center'>{TextHeading}</div>
        <div className='flex-initial text-custom-black text-xs md:text-base box-border p-2 text-start bg-custom-gray w-3/4 flex items-center justify-center'>{Text}</div>
      </div>
    </>
  )
}