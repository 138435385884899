import React, { useEffect, useState, useContext } from 'react'
import Navbar from './Navbar'
import { useParams } from 'react-router-dom'
import { ProductContext } from "./useContextFile"

import { motion } from "framer-motion";
import QuantityBtn from './QuantityBtn';
import Footer from './Footer';
import HeroBanner from './HeroBanner';

export default function ProductDetail() {
  let params = useParams()
  const [productDetail, setProductDetail] = useState(null)
  const { productData } = useContext(ProductContext)
  const [imgInFrame, setImgInFrame] = useState('')

  useEffect(() => {
    if (productData === null) return
    let productInfo = productData.find((element) => {
      return element.id === parseInt(params.id)
    })
    setProductDetail(productInfo)
    setImgInFrame(productInfo.image)
  }, [productData, params.id])

  const container = {
    hidden: { opacity: 1, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
        delayChildren: 0.2,
        staggerChildren: 0.2
      }
    }
  }

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  }

  return (
    <>
      <Navbar />
      <HeroBanner />
      {productDetail &&
        <div className='container mx-auto grid gap-5 grid-cols-12 pt-3 pb-3'>
          <motion.div variants={container} initial="hidden" animate="visible" className='col-span-12 md:col-span-6 box-border'>
            <motion.div variants={item} className='w-full bg-custom-black p-2 relative
            before:content-[""] before:w-5 before:absolute before:h-5 before:top-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green
            after:content-[""] after:w-5 after:absolute after:h-5 after:bottom-0 after:left-0 after:bg-gradient-to-r after:from-green-200 after:var-green-500 after:to-custom-green'>
              <img src={process.env.PUBLIC_URL + '/img/' + imgInFrame}
                alt={productDetail.name}
                className='w-full rounded-lg' />
            </motion.div>
            <QuantityBtn productInfo={productDetail} />
          </motion.div>
          <motion.div variants={container} initial="hidden" animate="visible" className='col-span-12 md:col-span-6 box-border'>
            <motion.div variants={item}><ProductInfoText TextHeading="作品" Text={productDetail.workTitle} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="商品規定" Text={productDetail.specification} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="售價" Text={productDetail.price} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="名稱" Text={productDetail.productName} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="廠商" Text={productDetail.factory} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="發售日" Text={productDetail.releaseDate} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="截訂日" Text={productDetail.preOrderLastDate} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="官方網站" Text={<a href={productDetail.website}>Link</a>} /></motion.div>
            <motion.div variants={item}><ProductInfoText TextHeading="描述" Text={productDetail.description} /></motion.div>
          </motion.div>
        </div>
      }
      <Footer />
    </>
  )
}

function ProductInfoText({ TextHeading, Text }) {
  return (
    <>
      <div className='w-full flex justify-center m-1'>
        <div className='flex-initial text-custom-white box-border p-2 bg-custom-black w-1/4 flex items-center justify-center'>{TextHeading}</div>
        <div className='flex-initial text-custom-black box-border p-2 text-start bg-custom-gray w-3/4 flex items-center justify-center'>{Text}</div>
      </div>
    </>
  )
}


