import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function Help() {
  return (
    <>
      <Navbar />
      <Footer />
    </>
  )
}
