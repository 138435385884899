import React, { useEffect, useState } from 'react'

export default function HeroBanner() {
    const [bannerImg, setBannerImg] = useState(null)

    useEffect(() => {
        const random = Math.round(Math.random() * (Banner.length - 1))

        setBannerImg(random)
    }, [])
    return (
        <>
            <div className='w-full sticky top-0 z-[-100] overflow-hidden p-0 mb-[-50%]'>
                <img
                    className='w-full'
                    src={process.env.PUBLIC_URL + '/img/'+ Banner[bannerImg]?.image}
                    alt=""
                />
                <div className='absolute top-0 left-0 h-full w-full
                bg-gradient-to-b from-transparent via-gray-200 to-custom-white
                '></div>
            </div>
        </>
    )
}

const Banner = [
    { image: "03_img_0.jpg" },
    { image: "Banner1.jpeg" },
    { image: "Banner2.jpeg" },
]