import React, { useContext, useState } from 'react'
import { CartContext } from "./useContextFile"

import { BsFillCartCheckFill } from "react-icons/bs";

import { motion } from "framer-motion";

export default function QuantityBtn({ productInfo }) {

    const { cartItems, setCartItems } = useContext(CartContext)

    let productIndexInCart = cartItems.findIndex((element) => {
        return element.id === productInfo.id
    })

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart === -1) ? 0 : cartItems[productIndexInCart].quantity
    )

    const handleAdd = () => {
        if (productIndexInCart === -1) {
            setCartItems(
                [{
                    id: productInfo.id,
                    name: productInfo.productName,
                    image: productInfo.image,
                    price: productInfo.price,
                    description: productInfo.description,
                    releaseDate: productInfo.releaseDate,
                    quantity: 1
                },
                ...cartItems]
            )
        }
        else {
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity++
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart + 1)
    }

    const handleSubtract = () => {
        if (cartItems[productIndexInCart].quantity === 1) {
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart, 1)
            setCartItems(newCartArray)
        }
        else {
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart - 1)
    }

    return (
        <>
            <div className='bg-custom-gray mt-3 mb-3 p-2 flex flex-col items-center justify-center rounded relative 
            before:content-[""] before:w-full before:absolute before:h-1 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green 
            after:content-[""] after:w-full after:absolute after:h-1 after:top-0 after:right-0 after:bg-gradient-to-r after:from-custom-green after:var-green-500 after:to-green-200
            '>
                <div className='w-full flex items-center justify-center m-2'>
                    <BsFillCartCheckFill style={{ fontSize: '2.5em' }} className='text-custom-green' />
                    <p className='px-2'>Shopping Cart</p>
                </div>

                <div className='w-full flex items-center justify-around p-1 m-2'>

                    <motion.button whileHover={{ scale: 1.2 }}
                        whileTap={{
                            scale: 0.8,
                            rotate: 360,
                            borderRadius: "100%"
                        }}
                        className='flex items-center justify-center bg-custom-black rounded-lg' onClick={handleSubtract}>
                        <div className='text-custom-white px-3 py-1'>-</div>
                    </motion.button>


                    <p className={numInCart > 0 ? 'text-custom-green' : ''}>Quantity: {numInCart}</p>

                    <motion.button whileHover={{ scale: 1.2 }}
                        whileTap={{
                            scale: 0.8,
                            rotate: 360,
                            borderRadius: "100%"
                        }}
                        className='flex items-center justify-center bg-custom-black rounded-lg' onClick={handleAdd}>
                        <div className='text-custom-white px-3 py-1'>+</div>
                    </motion.button>

                </div>
            </div>
        </>
    )
}