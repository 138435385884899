import React from 'react'
import { BsFacebook, BsInstagram, BsGithub, BsWhatsapp, BsTwitter, BsFillTelephoneFill } from "react-icons/bs"
import { AiFillMail } from "react-icons/ai"
import { TiLocation } from "react-icons/ti"

export default function Footer() {
    return (
        <>
            <div className='w-full mx-auto grid gap-5 grid-cols-12 mt-3 bg-custom-black py-8 sm:py-12 lg:py-16'>

                <div className='col-span-12 md:col-span-4 box-border p-3'>
                    <div className='flex flex-col justify-center'>
                        <h1 className='text-custom-white px-3 py-2 relative 
                        before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-400 before:to-custom-green text-lg'>ABOUT US</h1>
                        <p className='text-custom-white px-3 py-2'>This is the online shop for product of anime</p>
                        <div className='flex flex-wrap'>
                            <div className='p-2 mx-1 '>
                                <BsFacebook className='text-3xl text-custom-white' />
                            </div>
                            <div className='p-2 mx-1 '>
                                <BsInstagram className='text-3xl text-custom-white' />
                            </div>
                            <div className='p-2 mx-1 '>
                                <BsGithub className='text-3xl text-custom-white' />
                            </div>
                            <div className='p-2 mx-1 '>
                                <BsTwitter className='text-3xl text-custom-white' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-span-12 md:col-span-4 box-border p-3'>
                    <div className='flex flex-col justify-center'>
                        <h1 className='text-custom-white px-3 py-2 relative 
                        before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-400 before:to-custom-green text-lg'>ADDRESS</h1>
                        <div className='flex flex-wrap'>
                            <div className='flex w-full p-2 mx-1 items-center'>
                                <BsWhatsapp className='text-3xl text-custom-white' />
                                <p className='px-3 py-2 text-custom-white'>Tel: +852 XXXX XXXX</p>
                            </div>
                            <div className='flex w-full p-2 mx-1 items-center'>
                                <TiLocation className='text-3xl text-custom-white' />
                                <p className='px-3 py-2 text-custom-white'>Tuen Mun, Hong Kong</p>
                            </div>
                            <div className='flex w-full p-2 mx-1 items-center'>
                                <BsFillTelephoneFill className='text-3xl text-custom-white' />
                                <p className='px-3 py-2 text-custom-white'>Tel: +852 XXXX XXXX</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-span-12 md:col-span-4 box-border p-3'>
                    <div className='flex flex-col justify-center'>
                        <h1 className='text-custom-white px-3 py-2 relative 
                        before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-400 before:to-custom-green text-lg'>CONTACT US</h1>
                        <div className='p-2 mx-1'>
                            <AiFillMail className='text-3xl text-custom-white' />
                        </div>
                        <p className='text-custom-white px-3 py-2'>To: <br></br>fanhoman123@gmail.com</p>
                    </div>

                    <form action='mailto:fanhoman123@gmail.com'
                        encType='multipart/form-data'
                        method='Post'
                        className='flex flex-col justify-center'>
                        <label className='text-gray-100 px-3 py-2' htmlFor='email'>Email*</label>
                        <div className='text-custom-black px-3 py-2'>
                            <input className='w-full bg-gray-800 text-custom-white px-2 py-2' name='email' id='email' type='email' placeholder='example@gmail.com' required></input>
                        </div>
                        <label className='text-gray-100 px-3 py-2' htmlFor='message'>Message*</label>
                        <div className='text-custom-black px-3 py-2'>
                            <textarea className='w-full bg-gray-800 text-custom-white h-20 px-2 py-2' name='message' id='message' type='message' placeholder='Enter your comments' required></textarea>
                        </div>
                        <button type='reset' className='text-custom-white mt-1 mb-1 p-2 bg-gradient-to-r from-red-400 to-custom-red'>Reset</button>
                        <button type='submit' className='text-custom-white mt-1 mb-1 p-2 bg-gradient-to-r from-green-400 to-custom-green'>Submit</button>
                    </form>

                </div>

                <div className='col-span-12 box-border p-3'>
                    <p className='text-custom-white px-3 py-2 text-center relative 
                        before:content-[""] before:w-full before:absolute before:h-0.5 before:top-0 before:right-0 before:bg-gradient-to-r before:from-green-400 before:to-custom-green'>Copyright © [2022] [Tom Fan]. All rights reserved.</p>
                </div>

            </div>
        </>
    )
}
