import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BsFillCartCheckFill, BsQuestionCircleFill, BsFillBellFill, BsBuilding, BsFillStarFill, BsFillHexagonFill } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai"




export default function Navbar() {

    const [navbarOpen, setNavbarOpen] = useState(false)

    const handleNavbarOpen = () => {
        setNavbarOpen(navbarOpen => !navbarOpen)
    }

    return (
        <>
            <nav className='w-full top-0 left-0 z-50 fixed'>
                <div className='bg-custom-black flex h-[8vh] w-full justify-between items-center'>
                    <Link to='/'>
                        <div className='mx-5 my-3 text-custom-white'>Logo</div>
                    </Link>

                    <div className={'burger mx-5 my-3 p-1 cursor-pointer ' + (navbarOpen === true ? '' : 'rounded border-2 border-solid border-custom-white')} onClick={handleNavbarOpen}>
                        <div className={'w-8 h-1 bg-custom-white m-1 ease-in-out duration-300 ' + (navbarOpen === true ? 'rotate-[45deg] translate-y-2' : '')}></div>
                        <div className={'w-8 h-1 bg-custom-white m-1 ease-in-out duration-300 ' + (navbarOpen === true ? 'opacity-0' : 'opacity-100')}></div>
                        <div className={'w-8 h-1 bg-custom-white m-1 ease-in-out duration-300 ' + (navbarOpen === true ? 'rotate-[-45deg] translate-y-[-0.5rem]' : '')}></div>
                    </div>
                </div>

                <div className={'body absolute right-0 md:w-1/4 w-full bg-custom-black h-[92vh] top-[8vh] flex flex-col items-center ease-in-out duration-300 ' + (navbarOpen === true ? 'translate-x-0' : 'translate-x-full')}>
                    <ul className='flex flex-col w-full mx-auto px-10'>
                        <Link to='/'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <AiFillHome style={{ fontSize: "2em" }} /><p>Home</p>
                            </li>
                        </Link>

                        <Link to='/NewRelease'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <BsFillBellFill style={{ fontSize: "2em" }} /><p>New Release</p>
                            </li>
                        </Link>

                        <Link to='/Figures'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <BsFillStarFill style={{ fontSize: "2em" }} /><p>Figures</p>
                            </li>
                        </Link>

                        <Link to='/Toys'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <BsFillHexagonFill style={{ fontSize: "2em" }} /><p>Toys</p>
                            </li>
                        </Link>

                        <Link to='/Factory'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <BsBuilding style={{ fontSize: "2em" }} /><p>Factory</p>
                            </li>
                        </Link>

                        <Link to='/CheckOut'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <BsFillCartCheckFill style={{ fontSize: "2em" }} /><p>Check out</p>
                            </li>
                        </Link>

                        <Link to='/Help'>
                            <li className='flex flex-col justify-center items-center mt-3 mb-3 p-3 block text-custom-white hover:text-custom-green relative before:content-[""] before:w-full before:absolute before:h-0.5 before:bottom-0 before:right-0 before:bg-gradient-to-r before:from-green-200 before:var-green-500 before:to-custom-green before:hover:animate-[lineLoading_2s_ease-in] before:origin-left'>
                                <BsQuestionCircleFill style={{ fontSize: "2em" }} /><p>Help</p>
                            </li>
                        </Link>
                    </ul>
                </div>
            </nav>
        </>
    )
}

