import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { CartContext, ProductContext, DarkModeContext } from './useContextFile'
import ProductDetail from './ProductDetail'
import Main from './Main'
import NewRelease from './NewRelease'
import Figures from './Figures'
import Toys from './Toys'
import Factory from './Factory'
import Help from './Help'
import CheckOut from './CheckOut'

function App() {

  const [cartItems, setCartItems] = useState([])
  const [productData, setProductData] = useState(null)
  const [darkModeOpen, setDarkModeOpen] = useState(false)

  useEffect(() => {
    fetch('https://fanman95.github.io/Json/Product.json')
      .then(response => response.json())
      .then(data => setProductData(data))
  }, [])

  return (
    <BrowserRouter>
      <DarkModeContext.Provider value={{ darkModeOpen, setDarkModeOpen }}>
        <ProductContext.Provider value={{ productData, setProductData }}>
          <CartContext.Provider value={{ cartItems, setCartItems }}>
            <Routes>

              <Route path="/" element={<Main />}>
              </Route>

              <Route path="/ProductDetail" element={<ProductDetail />}>
                <Route path=":id" element={<ProductDetail />} />
              </Route>

              <Route path="/NewRelease" element={<NewRelease />}>
              </Route>

              <Route path="/Figures" element={<Figures />}>
              </Route>

              <Route path="/Toys" element={<Toys />}>
              </Route>

              <Route path="/Factory" element={<Factory />}>
              </Route>

              <Route path="/Help" element={<Help />}>
              </Route>

              <Route path="/CheckOut" element={<CheckOut />}>
              </Route>

              <Route path="/*" element={<p>404</p>}>
              </Route>
            </Routes>
          </CartContext.Provider>
        </ProductContext.Provider>
      </DarkModeContext.Provider>
    </BrowserRouter >
  );
}

export default App;
