import React, { useEffect, useState, useContext } from 'react'
import { ProductContext, DarkModeContext } from "./useContextFile"
import Card from './Card'
import { motion } from "framer-motion"
import Navbar from './Navbar'
import Footer from './Footer'
import Category from './Category'
import MainHeroBanner from './MainHeroBanner'

export default function Main() {
  const [newReleasesList, setNewReleasesList] = useState(null)
  const [newFigureList, setNewFigureList] = useState(null)
  const [newNendoroidList, setNewNendoroidList] = useState(null)

  const { productData } = useContext(ProductContext)
  const { darkModeOpen } = useContext(DarkModeContext)

  useEffect(() => {
    if (productData === null) return
    const newProduct = [...productData]
    setNewReleasesList(newProduct.reverse())

    const figureInfo = productData.filter((element) => {
      return element.category === "Figure"
    })
    setNewFigureList(figureInfo.reverse())

    const nendoroidInfo = productData.filter((element) => {
      return element.category === "Nendoroid"
    })
    setNewNendoroidList(nendoroidInfo.reverse())

  }, [productData])

  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2
      }
    }
  }

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  }

  return (
    <>
      <Navbar />
      <MainHeroBanner />
      <div className={darkModeOpen === false ? "bg-custom-white" : "bg-custom-white"}>
        
        {newReleasesList &&
          <motion.div className='container mx-auto grid gap-5 grid-cols-12 pt-3 pb-3' variants={container} initial="hidden" animate="visible">

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newReleasesList[0]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newReleasesList[1]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newReleasesList[2]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newReleasesList[3]?.id} />
            </motion.div>

          </motion.div>
        }

        {newFigureList &&
          <motion.div className='container mx-auto grid gap-5 grid-cols-12 pt-3 pb-3' variants={container} initial="hidden" animate="visible">

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newFigureList[0]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newFigureList[1]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newFigureList[2]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newFigureList[3]?.id} />
            </motion.div>

          </motion.div>
        }

        {newNendoroidList &&
          <motion.div className='container mx-auto grid gap-5 grid-cols-12 pt-3 pb-3' variants={container} initial="hidden" animate="visible">

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newNendoroidList[0]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newNendoroidList[1]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newNendoroidList[2]?.id} />
            </motion.div>

            <motion.div variants={item} className='col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 box-border'>
              <Card cardNum={newNendoroidList[3]?.id} />
            </motion.div>

          </motion.div>
        }
        <Category />
        <Footer />
      </div>
    </>
  )
}


