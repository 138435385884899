import React from 'react'

export default function MainHeroBanner() {



    return (
        <>
            <div className='h-[200vh] w-full relative box-border'>
                <div className='absolute w-full h-full top-0 bottom-0 left-0 right-0 box-border' style={{ clip: 'rect(0,auto,auto,0)' }}>

                    <div className='sticky top-0 left-0 w-full h-[100vh] rounded-lg bg-red-300'>
                      
                            <div className='fixed top-[50%] left-[50%]' style={{ transform: 'translate(-50%, -50%)' }}>
                                <h1 className='text-3xl md:text-5xl text-black'>Figures Online Shop</h1>
                                <p className='text-3xl md:text-5xl text-black'>Hong Kong Purchasing Agent</p>
                            </div>
                       
                    </div>

                    <div className='w-full h-[100vh] bg-blue-300 absolute top-0 left-0 rounded-lg' style={{ clip: 'rect(0,auto,auto,0)' }}>
                       
                            <div className='fixed top-[50%] left-[50%]' style={{ transform: 'translate(-50%, -50%)' }}>
                                <h1 className='text-3xl md:text-5xl text-white'>Figures Online Shop</h1>
                                <p className='text-3xl md:text-5xl text-white'>Hong Kong Purchasing Agent</p>
                            </div>
                        
                    </div>



                </div>
            </div>
        </>
    )
}
/* 
<div className='h-[200vh] w-full relative box-border'>
<div className='absolute w-full h-full top-0 bottom-0 left-0 right-0 box-border' style={{ clip: 'rect(0,auto,auto,0)' }}>

    <div className='sticky top-0 left-0 w-full h-[100vh] rounded-lg bg-red-200'>
        <div className='flex items-center justify-center h-full w-full'>
            <h1 className='text-8xl text-purple-900'>Figures Online Shop</h1>
        </div>
    </div>

    <div className='w-full h-[100vh] bg-blue-400 absolute top-0 left-0'>
        <div className='h-full w-full flex items-center justify-center'>
            <div className='fixed top-[50%] left-[50%]' style={{ transform: 'translate(-50%, -50%)' }}>
                <h1 className='text-8xl text-black'>Figures Online Shop</h1>
            </div>
        </div>
    </div>



</div>
</div> */






