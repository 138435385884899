import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ProductContext } from "./useContextFile"

export default function Card({ cardNum }) {
    const { productData } = useContext(ProductContext)

    const [cardInfo, setCardInfo] = useState(null)
    useEffect(() => {
        let info = productData.find((element) => {
            return element.id === cardNum
        })
        setCardInfo(info)
    }, [cardNum, productData])

    return (
        <>
            {cardInfo &&
                <div className='flex justify-center h-full'>
                    <div className="rounded-lg shadow-lg bg-custom-white max-w-sm overflow-hidden flex flex-col">
                        <Link to={'/ProductDetail/' + cardInfo.id}>
                            <div className='overflow-hidden aspect-square w-full'>
                                <img className="rounded-t-lg hover:scale-110" src={process.env.PUBLIC_URL + '/img/' + cardInfo.image} alt="" />
                            </div>
                        </Link>
                        <div className="p-6 flex flex-col h-full">
                            <h1 className="text-xl font-medium mb-2 mt-2 font-serif text-custom-black">{cardInfo.productName}</h1>
                            <p className="text-base mb-4 font-sans text-custom-black">
                                {cardInfo.workTitle}
                            </p>
                            {cardInfo.preOrder &&
                                <p className='text-base text-custom-green mb-4 font-sans mt-auto'>接受預訂</p>
                            }

                            {!cardInfo.preOrder &&
                                <p className='text-base text-custom-red mb-4 font-sans mt-auto'>截止預訂</p>
                            }

                            <p className='text-base font-sans text-custom-black mt-auto text-end mt-auto'>${cardInfo.price}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

