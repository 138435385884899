import React from 'react'
import { Link } from 'react-router-dom'

export default function Category() {
    return (
        <div className="bg-custom-white my-2">
            <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="w-full mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
                    <h1 className="text-2xl font-extrabold text-custom-black">Category</h1>

                    <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
                        {callouts.map((callout) => (
                            <div key={callout.name} className="group relative">
                                <div className="relative w-full h-80 bg-custom-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">

                                    <img
                                        src={process.env.PUBLIC_URL + '/img/' + callout.imageSrc}
                                        alt={callout.imageAlt}
                                        className="w-full h-full object-top object-cover"
                                    />

                                </div>
                                <Link to={'/' + callout.href}>
                                    <h3 className="mt-6 text-sm text-gray-900">
                                        <span className="absolute inset-0" />
                                        {callout.name}
                                    </h3>
                                </Link>
                                <p className="text-base text-gray-900">{callout.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const callouts = [
    {
        name: 'Figures',
        description: 'Artistic & beautifully painted',
        imageSrc: '8e9683658ba8b4bf986ed83b11b2de3a.webp',
        imageAlt: 'Artistic & beautifully painted',
        href: 'Figures',
    },
    {
        name: 'Nendoroids',
        description: 'Features cute & chibi-style figures',
        imageSrc: 'a3c2fe1c9def297044c118505c1871cb.webp',
        imageAlt: 'Features cute & chibi-style figures',
        href: 'Nendoroids',
    },
    {
        name: 'Figmas',
        description: 'Super-sized portable figures',
        imageSrc: '1f7545bc9b0780773e06b0e4dce64ac2.webp',
        imageAlt: 'Super-sized portable figures',
        href: 'Figmas',
    },
]